import React, { FC, useEffect, useState } from "react"

import { QuestionCircleOutlined } from "@ant-design/icons"
import {
    Button,
    DatePicker,
    InputNumber,
    Popover,
    Radio,
    Select,
    Space,
    Tooltip,
    Typography
} from "antd"
import moment from "moment"

import { getCountDaysFromPeriod } from "../../../../../../helpers/utils/reports"
import { periodDescription } from "../../../../constants/tooltips"
import { useActions } from "../../../../hooks/useActions"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import {initialPeriodState} from "../../../../../../app/providers/redux/slices/reports/params/period/periodSlice";

interface IPeriodSelectorProps {
    isCompare: boolean
}

const PeriodSelector: FC<IPeriodSelectorProps> = ({ isCompare }) => {

    const format = "DD.MM.YYYY"
    const startTime = { hour: 0, minute: 0, second: 0 }
    const endTime = { hour: 23, minute: 59, second: 59 }
    const { isFixedHeader } = useTypedSelector((state)=>state.dashboard)
    const period = useTypedSelector((state) => state.period)
    const { details } = useTypedSelector((state) => state.details)
    const { setPeriod, setAllDataFetching, setDetails } = useActions()
    const [isOpenOutside,setIsOpenOutside] = useState(false)
    const [ currentDateSelector, setCurrentDateSelector ] = useState(period.select)
    useEffect(()=>{
        setCurrentDateSelector(period.select)
    },[period.select])

    const [ currentDate, setCurrentDate ] = useState<any>({ start: period.start, end: period.end, interval: "" })
    const [ nDays, setNDays ] = useState({ nStart: null, nEnd: null })
    const [ visibleDateModal, setVisibleDateModal ] = useState(false)
    let isCohort = location.pathname.includes("cohort")
    useEffect(() => {
        setPeriod(initialPeriodState)
    }, [])

    const onChangeRadio = (value: string) => {
        // const { value } = e.target

        let startEnd: { [key: string]: () => {start: moment.Moment, end: moment.Moment, interval?: string} } = {
            today: () => {
                const start = moment().set(startTime)
                const end = moment().set(endTime)
                return { start, end }
            },
            yesterday: () => {
                const start = moment().subtract(1, "days").set(startTime)
                const end = moment().subtract(1, "days").set(endTime)
                return { start, end }
            },
            last7days: () => {
                const start = moment().subtract(1, "weeks").set(startTime)
                const end = moment().set(endTime)
                return { start, end }
            },
            last30days: () => {
                const start = moment().subtract(29, "days").set(startTime)
                const end = moment().set(endTime)
                return { start, end }
            },
        }
        const payload = startEnd[value]()
        payload.interval = value
        setCurrentDate({ start: payload.start, end: payload.end, interval: value })

        setCurrentDateSelector("")
        const periodData = {
            start: payload.start,
            end: payload.end,
            interval: payload.interval || period.interval,
            select: ""
        }
        setPeriod({
            ...periodData
        })
        setAllDataFetching({value:true})
    }

    const onChangeDate = (date: moment.Moment | null, type: string) => {
        if (!date) return

        let start = currentDate.start
        let end = currentDate.end

        if (type === "start") {

            if (date.isAfter(end)) {
                start = date
                end = date
            } else {
                start = date
            }

        } else if (type === "end") {
            if (date.isAfter(start)) {
                end = date
            } else {
                start = date
                end = date
            }

        }


        setCurrentDate({ start: start, end: end, interval: "" })
    }
    const onChangeType = (value: string) => {
        let start = null
        let end = null
        let interval = ""
        setCurrentDateSelector(value)

        switch (value) {
            case "selectdates":
                setVisibleDateModal(true)
                interval = "selectdates"
                return
            case "ndaysago":
                setIsOpenOutside(true)
                setVisibleDateModal(true)
                interval = "ndaysago"
                return
            case "today":
                start = moment().set(startTime)
                end = moment().set(endTime)
                interval = "today"
                break
            case "yesterday":
                start = moment().subtract(1, "days").set(startTime)
                end = moment().subtract(1, "days").set(endTime)
                interval = "yesterday"
                break
            case "thisweek":
                start = moment().isoWeekday(1).set(startTime)
                end = moment().set(endTime)
                interval = "thisweek"
                break
            case "lastweek":
                start = moment().subtract(1, "week").isoWeekday(1).set(startTime)
                end = moment().subtract(1, "week").isoWeekday(7).set(endTime)
                interval = "lastweek"
                break
            case "thismonth":
                start = moment().startOf("month").set(startTime)
                end = moment().set(endTime)
                interval = "thismonth"
                break
            case "lastmonth":
                start = moment().subtract(1, "month").startOf("month").set(startTime)
                end = moment().subtract(1, "month").endOf("month").set(endTime)
                interval = "lastmonth"
                break
            case "thisquart":
                start = moment().startOf("quarters").set(startTime)
                end = moment().set(endTime)
                interval = "thisquart"
                break
            case "lastquart":
                start = moment().subtract(1, "quarters").startOf("quarters").set(startTime)
                end = moment().subtract(1, "quarters").endOf("quarters").set(startTime)
                interval = "lastquart"
                break
            case "thisyear":
                start = moment().startOf("year").set(startTime)
                end = moment()
                interval = "thisyear"
                break
            case "lastyear":
                start = moment().subtract(1, "year").startOf("year").set(startTime)
                end = moment().subtract(1, "year").endOf("year").set(startTime)
                interval = "lastyear"
                break
            case "last7days":
                start = moment().subtract(6, "days").set(startTime)
                end = moment().set(endTime)
                interval = "last7days"
                break
            case "last14days":
                start = moment().subtract(13, "days").set(startTime)
                end = moment().set(endTime)
                interval = "last14days"
                break
            case "last30days":
                start = moment().subtract(29, "days").set(startTime)
                end = moment().set(endTime)
                interval = "last30days"
                break
            case "last365days":
                start = moment().subtract(365, "days").set(startTime)
                end = moment().set(endTime)
                interval = "last365days"
                break
            case "allthetime":
                start = moment().year(2000).set(startTime)
                end = moment().set(endTime)
                interval = "allthetime"
                break

            default:
                start = moment().set(startTime)
                end = moment().set(endTime)
                break
        }
        ""
        const payload = { start, end, interval }

        if (isCohort) {
            const countDays = getCountDaysFromPeriod(payload)

            const setDetail = (value: string) => {
                setDetails(value)
            }
            switch (details) {
                case "q":
                    if (countDays >= 1095) {
                        setDetail("y")
                    }
                    break
                case "m":
                    if (countDays >= 365) {
                        setDetail("q")
                    }
                    break
                case "w":
                    if (countDays >= 180) {
                        setDetail("m")

                    }
                    break
                case "d":
                    if(countDays >= 180){
                        setDetail("m")
                    }else{
                        setDetail("w")
                    }
                    break
            }
        }

        setCurrentDate({
            start: payload.start,
            end: payload.end,
            interval: payload.interval
        })
        setPeriod({
            start: payload.start,
            end: payload.end,
            interval: payload.interval,
            select: value
        })
        setTimeout(()=>{
            setAllDataFetching({value:true})
        },600)
    }

    const onApply = () => {

        let start = currentDate.start
        let end = currentDate.end
        if (isCohort) {
            const momentStart = moment(currentDate.start)
            const momentEnd = moment(currentDate.end)
            const countDays = momentEnd.diff(momentStart,"days")
            const setDetail = (value: string) => {
                setDetails(value)
            }
            if (countDays >= 1095) {
                setDetail("y")
            } else if (countDays >= 730 || countDays >= 365) {
                setDetail("q")
            } else if (countDays >= 180) {
                setDetail("m")
            } else {
                setDetail("w")
            }
        }

        setCurrentDateSelector("")
        let periodData = {
            start,
            end,
            select: currentDateSelector,
            interval: currentDate.interval, days: undefined

        }

        if (currentDate.interval === "ndaysago" && currentDate.days) {
            periodData.days = currentDate.days;
        }

        setPeriod({
          ...periodData
        })
        setAllDataFetching({value:true})
        setVisibleDateModal(false)
    }

    const onChangeN = (type: string, value: number) => {
        let _nStart = nDays.nStart ? nDays.nStart : 0
        let _nEnd = nDays.nEnd ? nDays.nEnd : 0
        if (type === "nStart") _nStart = value
        if (type === "nEnd") _nEnd = value
        let start = moment()
        let end = moment()

        start = start.subtract(_nEnd, "days").set(startTime)
        end = end.subtract(_nStart, "days").set(endTime)
        if (start.isAfter(end)) {
            setCurrentDate({ start: end, end: start, interval: "ndaysago",days:{nStart:_nStart, nEnd:_nEnd}})
        } else {
            setCurrentDate({ start: start, end: end, interval: "ndaysago",days:{nStart:_nStart, nEnd:_nEnd}})
        }

        setNDays((prevVal) => ({
            ...prevVal,
            [type]: value
        }))
    }

    const DP = ({ value, type }: any) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <DatePicker
            value={moment(value)}
            onChange={(date) => onChangeDate(date, type)}
            format={format}
            // disabledMinutes={true}
            allowClear={false}
            style={{ width: 120 }}
            disabled={isCompare}
        />
    )

    return (
        <Space size="large" style={{ ...(isFixedHeader ? { width:"fit-content" } : {}) }}>
            { !isFixedHeader && <>
                <Radio.Group
                    value={period.interval}
                    onChange={(e) => onChangeRadio(e.target.value)}
                    buttonStyle="solid"
                    disabled={isCompare}
                >
                    <Radio.Button value="today">Сегодня</Radio.Button>
                    <Radio.Button value="yesterday">Вчера</Radio.Button>
                    <Radio.Button value="last7days">7 дней</Radio.Button>
                    <Radio.Button value="last30days">30 дней</Radio.Button>
                </Radio.Group>

                <Select
                    style={{ width: "100%", minWidth: 200 }}
                    value={currentDateSelector ? currentDateSelector : null}
                    placeholder="Выбрать период"
                    onSelect={(value) => {
                        onChangeType(value)
                    }}
                    virtual={false}
                    getPopupContainer={trigger => trigger.parentNode}
                    disabled={isCompare}
                >
                    <Select.Option value="selectdates">выбрать даты</Select.Option>
                    <Select.Option value="today">сегодня</Select.Option>
                    <Select.Option value="yesterday">вчера</Select.Option>
                    <Select.Option value="thisweek">эта неделя</Select.Option>
                    <Select.Option value="lastweek">прошлая неделя</Select.Option>
                    <Select.Option value="thismonth">этот месяц</Select.Option>
                    <Select.Option value="lastmonth">прошлый месяц</Select.Option>
                    <Select.Option value="thisquart">этот квартал</Select.Option>
                    <Select.Option value="lastquart">прошлый квартал</Select.Option>
                    <Select.Option value="thisyear">этот год</Select.Option>
                    <Select.Option value="lastyear">прошлый год</Select.Option>
                    <Select.Option value="last7days">последние 7 дней</Select.Option>
                    <Select.Option value="last14days">последние 14 дней</Select.Option>
                    <Select.Option value="last30days">последние 30 дней</Select.Option>
                    <Select.Option value="last365days">последние 365 дней</Select.Option>
                    <Select.Option value="ndaysago">N дней назад</Select.Option>
                </Select>
            </>}

            <Popover
                content={
                    currentDateSelector === "ndaysago" && isOpenOutside ?
                        <Space size="small" direction="vertical">
                            <Space>
                                <Typography.Text type="secondary">от</Typography.Text>
                                <InputNumber
                                    disabled={isCompare}
                                    value={nDays.nStart}
                                    onChange={(val) => onChangeN("nStart", typeof val === "number" ? val : 0)}
                                    style={{ width: 100 }}
                                />

                                <Typography.Text type="secondary">до</Typography.Text>
                                <InputNumber
                                    disabled={isCompare}
                                    value={nDays.nEnd}
                                    onChange={(val) => onChangeN("nEnd", typeof val === "number" ? val : 0)}
                                    style={{ width: 100 }}
                                />
                            </Space>

                            <div>
                                {moment(currentDate.start).format(format).toUpperCase()}&nbsp;&nbsp;—&nbsp;&nbsp;{moment(currentDate.end).format(format).toUpperCase()}
                            </div>

                            <Button
                                type="primary"
                                style={{ marginTop: 10 }}
                                disabled={isCompare}
                                onClick={() => onApply()}
                            >
                                Применить
                            </Button>
                        </Space>
                        :
                    <Space size="small" direction="vertical">
                        <Space>
                            <DP
                                value={currentDate.start}
                                type="start"
                            />
                            <Typography.Text type="secondary">—</Typography.Text>
                            <DP
                                value={currentDate.end}
                                type="end"
                            />
                        </Space>

                        <Button
                            type="primary"
                            disabled={isCompare}
                            style={{ marginTop: 10 }}
                            onClick={onApply}
                        >
                            Применить
                        </Button>
                    </Space>
                }
                title="Период"
                open={visibleDateModal}
                onOpenChange={(visible)=>{setVisibleDateModal(visible),setIsOpenOutside(false)}}
                trigger="contextMenu"
                placement="bottom"
                zIndex={999}
            >
                <div onClick={()=>setVisibleDateModal(true)}>
                    {moment(period.start).format(format).toUpperCase()}&nbsp;&nbsp;—&nbsp;&nbsp;{moment(period.end).format(format).toUpperCase()}
                </div>
            </Popover>
            <span style={{ marginLeft: -4, marginRight: 10, marginTop: 3 }}>
                <Tooltip
                    placement="bottom"
                    title={<div className="page-title-tooltip-text" dangerouslySetInnerHTML={{ __html: periodDescription }}></div>}
                    color="#fff"
                    zIndex={9999}
                    trigger="click"
                    overlayClassName="page-title-tooltip"
                >
                   {!isFixedHeader &&  <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 14 }}/>}
                </Tooltip>
            </span>
        </Space>
    )
}

export default PeriodSelector
